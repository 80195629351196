:root {
  --primary-light: #fafafa;
  --primary-medium-light: #ececec;
  --primary-main: #cccccc;
  --primary-dark: #424242;
  --primary-contrastText: #212121;

  --secondary-light: #fff8df;
  --secondary-main: #ffc800;
  --secondary-dark: #ff8b00;
  --secondary-contrastText: #ffffff;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: var(--primary-light);
}

::-webkit-scrollbar-thumb {
  background: var(--primary-main);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--primary-dark);
  cursor: grab;
}

.App {
  overflow-y: scroll;
}

header {
  position: sticky !important;
}

.hero-section {
  background-image: url('/src/components/images/annikaelze.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 768px) {
  .hero-section {
    background-image: url('/src/components/images/annikaelze-mobile.jpg');
    background-attachment: scroll;
  }
}

.about-text-desktop {
  margin-top: 2rem;
  width: 40%;
}

@media only screen and (max-width: 768px) {
  .about-text-desktop {
    width: 100%;
    background-color: var(--primary-light);
    color: var(--primary-contrastText);
    padding: 1rem;
  }
}

.footer {
    position: fixed;
    bottom: 0;
}

.activeColor {
    color: var(--secondary-dark) !important;
}

.inactiveColor {
    color: var(--primary-main) !important;
}

.bgContainer {
  width: 100vw;
}

.lightBG {
  background-color: var(--primary-light);
}

.medium-lightBG {
  background-color: var(--primary-medium-light);
}

.darkBG {
  background-color: var(--primary-dark);
  color: var(--primary-light);
}

.blackBG {
  background-color: var(--primary-contrastText);
}

.section {
  margin-bottom: 2rem;
}

.section-title {
  position: relative;
  display: inline-block;
  margin-bottom: 2rem;
}

.section-title::before {
  content: '';
  position: absolute;
  top: 65%;
  left: 0;
  width: 100%;
  height: 50%;
  background-color: var(--primary-main);
  transform: translateY(-50%);
}

.section-title h2 {
  margin: 0;
  padding: 1px;
  position: relative;
}

.simpleGrid {
  margin-top: 0.5rem;
  display: grid;
  grid-template-columns: 60% 40%;
  column-gap: 0.5rem;
}

.ratingIcon {
  margin-right: 0.2rem;
}

.skill-ships {
  margin-top: auto;
}